import React, { useEffect, useRef } from 'react';
import SectionHeading from '../Common/SectionHeading';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules'
import Link from 'next/link';

const PayAsUse = ({title=null, shortDescription=null}) => {
    const data1 = [
        ['Pipeline Insight', 20],
        ['Epidemiology Insight', 15],
        ['Rare Disease Insight', 5],
        ['Market Insight', 60],
    ];
    
    const data2 = [
        ['Oncology', 70],
        ['Neurodegenerative', 20],
        ['Ophthalmology', 5],
        ['Dermatology', 3],
        ['Respiratory', 2],
    ];
    const options1 = {
        chart: {
          type: 'pie',
        },
        title: {
            text: '', // Set the title text to an empty string
        },
        plotOptions: {
        pie: {
                colors: ['#67b7dc', '#6794dc', '#6771dc', '#1b5df3'],
                // Add custom colors to the colors array
            },
        },
        series: [
          {
            name: 'Categories',
            data: data1,
          },
        ],
    };
    // Options for the second pie chart
    const options2 = {
        chart: {
        type: 'pie',
    },
        title: {
        text: '',
    },
        plotOptions: {
        pie: {
            colors: ['#1b5df3', '#6794dc', '#6771dc', '#8067dc', '#a367dc'],
        },
    },
        series: [
        {
            name: 'Categories',
            data: data2, // Use the second set of data
        },
        ],
    };

    return (
        <>  
            <section id="packages" className="reviews-sec bg-image">
                <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-10 my-6">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="lg:col-span-4 col-span-12">
                            <p className='mb-0'>Pay as You Use</p>
                            <SectionHeading title={title} shortDescription={shortDescription} customClass="text-left"/>
                            <Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}} className='bg-[#1b5df3] btn'>Learn More</Link>
                        </div>
                        <div className="lg:col-span-8 col-span-12">


                            <Swiper
                                modules={[Navigation, Autoplay]}
                                speed={300}
                                parallax={true}
                                loop={true}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    },
                                }}
                            >
                                <SwiperSlide >
                                    <div>
                                        <HighchartsReact highcharts={Highcharts} options={options1} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div>
                                        <HighchartsReact highcharts={Highcharts} options={options2} />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PayAsUse;
